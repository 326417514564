<script>
import emitter from '/~/core/emitter'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'
import MenuPointsConverter from './components/menu-points-converter.vue'
import MenuPointsPartner from '../../components/rows/menu-points-partner.vue'

export default {
  name: 'profile-transfer-points',
  components: {
    MenuPointsConverter,
    MenuPointsPartner,
    BaseButton,
    BaseIcon,
    BaseAsidePage,
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ewalletLabels,
    }
  },
  data() {
    return {
      submitting: false,
      form: {
        to: null,
        amount: null,
      },
    }
  },
  computed: {
    submitDisabled() {
      return !this.form.to || !this.form.amount
    },
  },
  created() {
    emitter.on('menu:points-partners:select-partner', this.setFormTo)
  },
  beforeDestroy() {
    emitter.off('menu:points-partners:select-partner', this.setFormTo)
  },
  methods: {
    setFormTo(to) {
      this.form.to = to
    },
    onSelectClick() {
      if (this.submitting) return
      this.$router.push({ hash: '#profile-points-partners-added' })
    },
    onAmountChange(values) {
      this.form.amount = values.valueFrom
    },
    onSubmit() {
      this.submitting = true
    },
  },
}
</script>

<template>
  <base-aside-page :title="`Transfer my ${ewalletLabels.ewalletPoints}`">
    <div class="flex flex-1 flex-col">
      <div class="mb-2.5 font-bold text-eonx-neutral-600">To</div>
      <div
        class="mb-[30px] flex min-h-20 cursor-pointer items-center justify-between rounded-lg bg-white px-[15px] py-2.5"
        :class="[submitting && 'pointer-events-none opacity-80']"
        @click="onSelectClick"
      >
        <menu-points-partner v-if="form.to" :partner="form.to">
          <template #action>
            <div v-show="!submitting">
              <base-icon svg="plain/chevronright" size="sm" />
            </div>
          </template>
        </menu-points-partner>

        <div v-else class="flex w-full items-center">
          <div class="flex h-12 w-12 shrink-0 items-center justify-center">
            <base-icon svg="plain/fly-2" size="md" />
          </div>

          <div class="flex-1 truncate pl-[15px] font-bold">
            Select Points Partner
          </div>

          <base-icon svg="plain/chevronright" size="sm" />
        </div>
      </div>

      <div class="mb-2.5 font-bold text-eonx-neutral-600">Total</div>

      <div class="rounded-lg bg-white p-5">
        <menu-points-converter type="transfer" @converted="onAmountChange" />
      </div>

      <div class="mt-auto pt-[30px]">
        <base-button
          :disabled="submitDisabled"
          :loading="submitting"
          class="w-full"
          @click="onSubmit"
        >
          Confirm
        </base-button>
      </div>
    </div>
  </base-aside-page>
</template>
